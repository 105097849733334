<template>
  <v-app
    class="grey lighten-4"
    style="max-height: 100vh"
    v-if="SO_API_BASE_URL != null"
  >
    <NavBar
      v-if="
        $router.currentRoute.name !== 'home' &&
        currentUser.role != 'PublicDashboard'
      "
    />

    <v-main
      v-bind:class="{
        sientificLight: !this.darkmode && userSettings.theme != 'Colors',
        sientificDark: this.darkmode && userSettings.theme != 'Colors',
        backg: userSettings.theme == 'Colors' && !this.darkmode,
        backgdark: userSettings.theme == 'Colors' && this.darkmode,
      }"
      style="padding: 0"
    >
      <router-view></router-view>
    </v-main>

    <v-snackbar
      v-model="refresh"
      v-if="message != ''"
      bottom
      right
      :timeout="3000"
      :color="alertType == 'error' ? 'red' : 'green'"
      >{{ message }}</v-snackbar
    >

    <v-footer app v-if="$router.currentRoute.name === 'dashboardChooser'">
      <span>
        {{
          this.globalSettings.powered_by
            ? this.globalSettings.powered_by
            : "IoT Application And Hardware AB"
        }}</span
      >
    </v-footer>

    <data-hub v-if="permitted('Tag.View')" />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import { Roles } from "@/_helpers/Role";
import DataHub from "@/components/events/DataHub.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    DataHub,
  },

  data() {
    return {
      refresh: false,
      landing: false,
    };
  },

  updated() {
    if (this.$router.currentRoute.name == "home") {
      this.landing = true;
    }
  },
  async created() {
    this.$vuetify.theme.dark = this.darkmode;
    await this.getBaseUrl();
    await this.getGlobalSettings();

    if (this.status.loggedIn && this.currentUser.role != "PublicDashboard")
      await this.getUserSettings();

    if (this.globalSettings.branding)
      document.title = this.globalSettings.branding;

    if (this.whiteLabel) {
      const favi = document.getElementById("favicon");
      favi.href = window.location.origin + "/so-white-label-favicon.ico";
    }

    this.clearAlert();
  },
  computed: {
    ...mapState("alert", ["active", "message", "alertType"]),
    ...mapState("configuration", [
      "darkmode",
      "userSettings",
      "globalSettings",
    ]),
    ...mapState("users", ["status", "currentUser"]),

    Roles() {
      return Roles;
    },
  },
  methods: {
    ...mapActions({ clearAlert: "alert/clear" }),
    ...mapActions("configuration", [
      "getGlobalSettings",
      "getUserSettings",
      "getBaseUrl",
      "SO_API_BASE_URL",
    ]),
  },

  watch: {
    message(val) {
      if (val) {
        this.refresh = true;
      } else {
        this.refresh = false;
      }
    },

    refresh(value) {
      if (value === false) this.clearAlert();
    },
  },
};
</script>

<style>
.backg {
  /* background: rgb(235, 235, 235); */
  background-image: url("/bg.jpg");
  background-size: cover;
}
.backgdark {
  background-image: url("/bgdark.jpg");
  background-size: cover;
}

.sientificLight {
  background-color: #e9e9e9;
}

.sientificDark {
  background-color: #121212;
}
</style>
